@font-face {
  font-family: 'Caslon Antique';
  src: url('../fonts/caslon-antique/CaslonAntique.ttf');
  src: url('../fonts/caslon-antique/CaslonAntique.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Caslon Antique';
  src: url('../fonts/caslon-antique/CaslonAntique-Bold.ttf');
  src: url('../fonts/caslon-antique/CaslonAntique-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Caslon Antique';
  src: url('../fonts/caslon-antique/CaslonAntique-Italic.ttf');
  src: url('../fonts/caslon-antique/CaslonAntique-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Caslon Antique';
  src: url('../fonts/caslon-antique/CaslonAntique-BoldItalic.ttf');
  src: url('../fonts/caslon-antique/CaslonAntique-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Packard Antique';
  src: url('../fonts/packard-antique/PackardAntique.ttf');
  src: url('../fonts/packard-antique/PackardAntique.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Packard Antique';
  src: url('../fonts/packard-antique/PackardAntique-Bold.ttf');
  src: url('../fonts/packard-antique/PackardAntique-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
